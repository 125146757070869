import React from 'react';
import type { PropsWithChildren } from 'react';
import { Stack } from '@balance-web/stack';

/**
 * Global Visual Boundaries
 *
 * Provides an element thatis as big as the current window.
 *
 * Most importantly this is the Root flex parent that is at least the height of the current window.
 *
 * Any implementation in your components that wants to fill the height of it's parent then
 * use flexGrow=1 instead of height: 100vh ( which will incorrectly create uninstended scrollbars ).
 *
 */
export function AppWindow({ children }: PropsWithChildren<{}>) {
  return (
    <Stack flexGrow={1} minHeight="100vh" width="100%">
      {children}
    </Stack>
  );
}
