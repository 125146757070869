import type { ReactNode, Ref } from 'react';
import React, {
  cloneElement,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useForkedRef } from '@balance-web/utils';
import type { WithDataAttributeProp } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';

import { useTestId } from '../context';

export type CustomTriggerProps = {
  isOpen: boolean;
  triggerProps: WithDataAttributeProp<{ ref: Ref<any> }>;
};

type CallableTriggerChildren = (props: CustomTriggerProps) => ReactNode;
export type TriggerProps = WithDataAttributeProp<{
  children?: ReactNode | CallableTriggerChildren;
  disabled?: boolean;
}>;

const isCallableChildren = (
  children: ReactNode
): children is CallableTriggerChildren => {
  return typeof children === 'function';
};

const isRenderableElement = (
  children: ReactNode
): children is React.ReactElement => {
  return React.isValidElement(children);
};

export const Trigger = forwardRef<any, TriggerProps>(
  ({ children, disabled, data }, ref) => {
    if (children === undefined || children === null) {
      throw Error('zz');
    }

    const [isOpen, setIsOpen] = useState(false);
    const exposedTriggerRef = useRef<any>(null);
    const composedRef = useForkedRef(exposedTriggerRef, ref);

    const dataAttributes = buildDataAttributes(data);

    const parentTestId = useTestId();
    const triggerTestIdProp = parentTestId
      ? { 'data-testid': `${parentTestId}-trigger` }
      : {};

    let childrenElement;
    if (isCallableChildren(children)) {
      childrenElement = children({
        isOpen,
        triggerProps: {
          ref: composedRef,
          ...dataAttributes,
          ...triggerTestIdProp,
        },
      });
    } else if (isRenderableElement(children)) {
      childrenElement = cloneElement(children, {
        ...dataAttributes,
        ...triggerTestIdProp,
      });
    }

    /**
     * Radix only provides open/closed state through data-attributes so we have to observe
     * the element attributes and provide open/closed props ourselves.
     * https://www.radix-ui.com/docs/primitives/components/dropdown-menu#trigger
     */
    useLayoutEffect(() => {
      if (!isCallableChildren(children)) {
        return;
      }
      if (!exposedTriggerRef.current) {
        return;
      }

      const observer = new MutationObserver((mutations) => {
        mutations.forEach(function (mutation) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'aria-expanded'
          ) {
            setIsOpen(
              exposedTriggerRef.current?.getAttribute('aria-expanded') ===
                'true'
            );
          }
        });
      });

      observer.observe(exposedTriggerRef.current, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }, [children]);

    return (
      <DropdownMenuPrimitive.Trigger asChild disabled={disabled}>
        {childrenElement}
      </DropdownMenuPrimitive.Trigger>
    );
  }
);
