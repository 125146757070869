/** @jsx jsx */

import type { ComponentType } from 'react';
import { jsx } from '@balance-web/core';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';
import type { IconProps } from '@balance-web/icon';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';

import type { ButtonStylesProps, ColorScheme, Size, Variant } from './styles';
import { getButtonStyles } from './styles';
import { usePreventableClickHandler } from './utils';

type IconButtonProps = WithDataAttributeProp<{
  /** The label of the button, passed to `aria-label`. */
  label: string;
  /** The color of the button. */
  colorScheme?: ColorScheme;
  /** When true, the button will be disabled. */
  disabled?: boolean;
  /** The icon to place in the button */
  icon: ComponentType<IconProps>;
  /** Style of the button. */
  variant?: Variant;
  /** Provide an alternate type if the button is within a form. */
  type?: HTMLButtonElement['type'];
  /** The size of the button. */
  size?: Size;
}>;

export const IconButton = forwardRefWithAs<'button', IconButtonProps>(
  (
    {
      as: Tag = 'button',
      disabled = false,
      icon: Icon,
      label,
      size = 'medium',
      colorScheme = 'primary',
      variant = 'filled',
      data,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const rawTheme = useRawTheme();

    if (Tag === 'button') {
      props.type = props.type || 'button';
    }

    // styles
    const state: ButtonStylesProps = {
      borderRadius: '50%',
      disabled,
      size,
      colorScheme,
      variant,
      block: false,
      hasIcon: false,
      isIcon: true,
    };
    const buttonStyles = {
      ...getButtonStyles(state, theme, rawTheme),
      padding: 0, // remove native <button/> padding so icons are centered correctly
    };

    // handle "disabled" behaviour w/o disabling buttons
    const handleClick = usePreventableClickHandler(props, disabled);

    const dataAttributes = buildDataAttributes(data);

    return (
      <div>
        <Tag
          ref={ref}
          aria-disabled={disabled}
          aria-label={label}
          css={buttonStyles.root}
          {...dataAttributes}
          {...props}
          // must be after prop spread
          onClick={handleClick}
        >
          <Icon size={size} />
          <div
            className="hover-overlay"
            css={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              pointerEvents: 'none',
              ...buttonStyles.hoverOverlay,
            }}
          />
          <div
            className="focused-overlay"
            css={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              pointerEvents: 'none',
              ...buttonStyles.focusedOverlay,
            }}
          />
          <div
            className="pressed-overlay"
            css={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              pointerEvents: 'none',
              ...buttonStyles.pressedOverlay,
            }}
          />
        </Tag>
      </div>
    );
  }
);
