/** @jsx jsx */

import { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import type { FlexProps } from '@balance-web/flex';
import { Flex } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';
import type { DisallowStyleProps } from '@balance-web/utils';

import { IndicatorDot } from './IndicatorDot';
import type { BadgeToneColors, IndicatorToneColors } from './useToneColor';
import { useBadgeToneColor } from './useToneColor';

export type WeightType = 'bold' | 'subtle';

type ValidBoxProps = Pick<FlexProps, 'data' | 'id'>;
export type BadgeProps = {
  /** The label of the badge. */
  label: string;
  /** The tone of the badge. */
  tone?: BadgeToneColors;
  /** The visual "weight" of the badge. */
  weight?: WeightType;
} & DisallowStyleProps &
  ValidBoxProps;

/** A decorative indicator for communicating non-actionable, supplemental information. */
export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    // NOTE: we unfortunately must spread props because designs assign tooltips to non-interactive elements like this
    { data, id, label, tone = 'neutral', weight = 'subtle', ...tooltipProps },
    forwardedRef
  ) => {
    const { sizing } = useTheme();
    const toneColor = useBadgeToneColor({ tone });

    const isSubtle = weight === 'subtle';
    const height = sizing.xsmall; // FIXME: should be resolved by the `Box` component

    const badgeStyles = isSubtle
      ? {}
      : { backgroundColor: toneColor.backgroundColor };
    const textStyles = isSubtle ? {} : { color: toneColor.foregroundColor }; // FIXME: should be resolved by background provider on the `Box` component

    return (
      <Flex
        ref={forwardedRef}
        data={data}
        id={id}
        // flex styles
        alignItems="center"
        background="base"
        border={isSubtle ? 'standard' : undefined}
        borderRadius="full"
        gap="small"
        height={height}
        inline
        justifyContent="center"
        paddingLeft={isSubtle ? 'small' : 'medium'}
        paddingRight="medium"
        // TODO: remove custom styles
        css={badgeStyles}
        {...tooltipProps}
      >
        {isSubtle ? (
          <IndicatorDot tone={validateToneIsIndicatorFriendly(tone)} />
        ) : null}
        <Text
          color="muted"
          inline
          overflowStrategy="nowrap"
          size="small"
          weight="medium"
          // TODO: remove leading (after capsize implementation)
          leading="tighter"
          // TODO: remove custom styles
          css={textStyles}
        >
          {label}
        </Text>
      </Flex>
    );
  }
);

function validateToneIsIndicatorFriendly(
  tone: BadgeToneColors
): IndicatorToneColors {
  if (
    tone === 'critical-inverse' ||
    tone === 'neutral-inverse' ||
    tone === 'primary-inverse' ||
    tone === 'secondary' ||
    tone === 'secondary-inverse'
  ) {
    throw Error(`Indicator cannot have tone ${tone}`);
  }

  return tone;
}
