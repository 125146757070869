import { useTheme } from '@balance-web/theme';
import { useMemo } from 'react';

export type BadgeToneColors =
  | 'primary'
  | 'primary-inverse'
  | 'secondary'
  | 'secondary-inverse'
  | 'neutral'
  | 'neutral-inverse'
  | 'critical'
  | 'critical-inverse'
  | 'positive'
  | 'cautious'
  | 'informative'
  | 'disabled';

export type IndicatorToneColors = Exclude<
  BadgeToneColors,
  | 'primary-inverse'
  | 'secondary-inverse'
  | 'secondary'
  | 'neutral-inverse'
  | 'critical-inverse'
>;

export function useBadgeToneColor({
  tone = 'neutral',
}: {
  tone?: BadgeToneColors;
}) {
  const { primitives } = useTheme();

  const foregroundColor = useMemo(() => {
    if (tone === 'primary') {
      return primitives.colour.primary.k100;
    }

    if (tone === 'primary-inverse') {
      return primitives.colour.primary.k40;
    }

    if (tone === 'secondary') {
      return primitives.colour.secondary.k100;
    }

    if (tone === 'secondary-inverse') {
      return primitives.colour.secondary.k40;
    }

    if (tone === 'neutral') {
      return primitives.colour.neutral.k100;
    }

    if (tone === 'neutral-inverse') {
      return primitives.colour.neutral.k40;
    }

    if (tone === 'critical') {
      return primitives.colour.reserved.critical.k100;
    }

    if (tone === 'critical-inverse') {
      return primitives.colour.reserved.critical.k40;
    }

    if (tone === 'positive') {
      return primitives.colour.reserved.success.k100;
    }

    if (tone === 'cautious') {
      return primitives.colour.reserved.warning.k100;
    }

    if (tone === 'informative') {
      return primitives.colour.reserved.info.k100;
    }

    if (tone === 'disabled') {
      return primitives.colour.neutral.k80;
    }
  }, [primitives, tone]);

  const backgroundColor = useMemo(() => {
    if (tone === 'primary') {
      return primitives.colour.primary.k40;
    }

    if (tone === 'primary-inverse') {
      return primitives.colour.primary.k100;
    }

    if (tone === 'secondary') {
      return primitives.colour.secondary.k40;
    }

    if (tone === 'secondary-inverse') {
      return primitives.colour.secondary.k100;
    }

    if (tone === 'neutral') {
      return primitives.colour.neutral.k40;
    }

    if (tone === 'neutral-inverse') {
      return primitives.colour.surface.default.k3;
    }

    if (tone === 'critical') {
      return primitives.colour.reserved.critical.k40;
    }

    if (tone === 'critical-inverse') {
      return primitives.colour.reserved.critical.k100;
    }

    if (tone === 'positive') {
      return primitives.colour.reserved.success.k40;
    }

    if (tone === 'cautious') {
      return primitives.colour.reserved.warning.k40;
    }

    if (tone === 'informative') {
      return primitives.colour.reserved.info.k40;
    }

    if (tone === 'disabled') {
      return primitives.colour.neutral.interactions.inverse.light;
    }
  }, [primitives, tone]);

  return { backgroundColor, foregroundColor };
}

export function useIndicatorToneColor({
  tone = 'neutral',
}: {
  tone?: IndicatorToneColors;
}) {
  const { primitives } = useTheme();

  const backgroundColor = useMemo(() => {
    if (tone === 'primary') {
      return primitives.colour.primary.k60;
    }

    if (tone === 'neutral') {
      return primitives.colour.neutral.k40;
    }

    if (tone === 'critical') {
      return primitives.colour.reserved.critical.k60;
    }

    if (tone === 'positive') {
      return primitives.colour.reserved.success.k60;
    }

    if (tone === 'cautious') {
      return primitives.colour.reserved.warning.k60;
    }

    if (tone === 'informative') {
      return primitives.colour.reserved.info.k60;
    }

    if (tone === 'disabled') {
      return primitives.colour.neutral.k80;
    }
  }, [primitives, tone]);

  return { backgroundColor };
}
