/** @jsx jsx */
import React from 'react';
import type { PropsWithChildren } from 'react';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';

type FlexProps = React.ComponentProps<typeof Flex>;
export type AppLayoutProps = {
  header?: React.ReactElement;
} & Pick<FlexProps, 'direction'>;

/**
 * This component is supposed to be used in conjunction with AppWindow.
 *
 * AppWindow will set the parent height context to at least the height of the
 * current window.
 *
 * This component manages splitting the header area and everything else into two areas:
 *
 * header: whatever height rules the component in there specifies,
 * children: should fill remaining height.
 */
export function AppLayout(props: PropsWithChildren<AppLayoutProps>) {
  const { header, children, direction } = props;

  return (
    <>
      {header}
      <Flex
        direction={direction || 'column'}
        flexGrow={1}
        minHeight="100%"
        justifyContent="stretch"
        width="100%"
      >
        {children}
      </Flex>
    </>
  );
}
