/** @jsx jsx */

import type { ComponentType, HTMLAttributes } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import type { CSSObject, DataAttributeMap } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import type { IconProps } from '@balance-web/icon';
import { LoadingDots } from '@balance-web/loading';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';

import type { ButtonStylesProps, ColorScheme, Size, Variant } from './styles';
import { getButtonStyles } from './styles';
import { usePreventableClickHandler } from './utils';

export type BaseButtonProps = {
  /** The label of the button. */
  label: string;
  /** Test id for the button */
  data?: DataAttributeMap;
  /** An element rendered before the button content. */
  iconBefore?: ComponentType<IconProps>;
  /** An element rendered after the button content. */
  iconAfter?: ComponentType<IconProps>;
  /** The size of the button. */
  size?: Size;
  /** When true, the button will be disabled. */
  disabled?: boolean;
  /** When true, the button will fill the available width of its container. */
  block?: boolean;
  /** When true, the button will display a loading spinner. */
  loading?: boolean;
  /** Provide an alternate type if the button is within a form. */
  type?: 'submit' | 'button' | 'reset';
  /** Color of the button. */
  colorScheme?: ColorScheme;
  /** Style of the button. */
  variant?: Variant;
  /** Props to manipulate the border in detail. */
  borderRadius?: CSSObject['borderRadius'];
  borderLeftWidth?: CSSObject['borderLeftWidth'];
  borderTopWidth?: CSSObject['borderTopWidth'];
  borderRightWidth?: CSSObject['borderRightWidth'];
  borderBottomWidth?: CSSObject['borderBottomWidth'];
};

export type ButtonProps = BaseButtonProps;

export const Button = forwardRefWithAs<'button', ButtonProps>(
  (
    {
      as: Tag = 'button',
      colorScheme = 'primary',
      variant = 'filled',
      label,
      disabled = false,
      block = false,
      iconAfter: IconAfter,
      iconBefore: IconBefore,
      loading = false,
      size = 'medium',
      borderRadius,
      borderLeftWidth,
      borderTopWidth,
      borderRightWidth,
      borderBottomWidth,
      data,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const rawTheme = useRawTheme();

    const isDisabled = disabled || loading;
    const isLoading = loading;

    if (Tag === 'button') {
      props.type = props.type || 'button';
    }

    // styles
    const state: ButtonStylesProps = {
      block,
      borderRadius: borderRadius || theme.radii.full,
      fontWeight: theme.typography.fontWeight.bold,
      borderLeftWidth,
      borderTopWidth,
      borderRightWidth,
      borderBottomWidth,
      size,
      colorScheme,
      variant,
      disabled,
      hasIcon: !!IconBefore || !!IconAfter,
      isIcon: false,
    };
    const buttonStyles = getButtonStyles(state, theme, rawTheme);

    // handle "disabled" behaviour w/o disabling buttons
    const handleClick = usePreventableClickHandler(props, isDisabled);

    return (
      <Tag
        ref={ref}
        aria-disabled={isDisabled}
        css={{
          ...buttonStyles.root,
        }}
        {...(data ? buildDataAttributes(data) : undefined)}
        {...props}
        // must be after prop spread
        onClick={handleClick}
      >
        <Flex alignItems="center" gap="small">
          {IconBefore && (
            <HiddenWhenLoading isLoading={isLoading}>
              <IconBefore size={size} />
            </HiddenWhenLoading>
          )}
          <HiddenWhenLoading isLoading={isLoading}>{label}</HiddenWhenLoading>
          {isLoading && (
            <Center>
              <LoadingDots
                color="inherit"
                label="button loading indicator"
                size={size}
              />
            </Center>
          )}
          {IconAfter && (
            <HiddenWhenLoading isLoading={isLoading}>
              <IconAfter size={size} />
            </HiddenWhenLoading>
          )}
        </Flex>
        <div
          className="hover-overlay"
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            pointerEvents: 'none',
            ...buttonStyles.hoverOverlay,
          }}
        />
        <div
          className="focused-overlay"
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            pointerEvents: 'none',
            ...buttonStyles.focusedOverlay,
          }}
        />
        <div
          className="pressed-overlay"
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            pointerEvents: 'none',
            ...buttonStyles.pressedOverlay,
          }}
        />
      </Tag>
    );
  }
);

Button.displayName = 'DeprecatedButton';

// Styled components
// ------------------------------

/**
 * Hide with opacity so elements maintain their dimensions, and the button
 * doesn't change shape.
 */
const HiddenWhenLoading = ({
  isLoading,
  ...props
}: HTMLAttributes<HTMLSpanElement> & { isLoading: boolean }) => {
  return <span css={isLoading ? { opacity: 0 } : null} {...props} />;
};

/** Position the loading indicator */
const Center = (props: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      css={{
        left: '50%',
        position: 'absolute',
        transform: 'translateX(-50%)',
      }}
      {...props}
    />
  );
};
