import React from 'react';
import { useTheme } from '@balance-web/theme';
import type { DisallowStyleProps } from '@balance-web/utils';

import type { IndicatorToneColors } from './useToneColor';
import { useIndicatorToneColor } from './useToneColor';

export type IndicatorDotProps = {
  /**
   * When the intent isn't provided by text, you must supply an "aria-label" for
   * assistive tech users.
   */
  'aria-label'?: string;
} & DisallowStyleProps & {
    tone: IndicatorToneColors;
  };

/** A small decorative indicator used to call attention to an item. */
export const IndicatorDot = ({
  'aria-label': ariaLabel,
  tone,
}: IndicatorDotProps) => {
  const { spacing } = useTheme();
  const toneColor = useIndicatorToneColor({ tone });
  const size = spacing.small;

  return (
    <div
      aria-label={ariaLabel}
      style={{
        backgroundColor: toneColor.backgroundColor,
        borderRadius: size,
        height: size,
        width: size,
      }}
    />
  );
};
