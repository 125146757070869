/** @jsx jsx */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { MenuItemProps } from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';
import type { WithDataAttributeProp } from '@balance-web/core';
import {
  buildDataAttributes,
  getTestIdFromDataAttributes,
  jsx,
} from '@balance-web/core';

import { useTestId } from '../context';

import { useItemStyles } from './shared-styles';

export type ItemContainerProps = WithDataAttributeProp<
  {
    children: ReactNode;
  } & Omit<MenuItemProps, 'onClick'>
>;

export const ItemContainer = ({ data, ...props }: ItemContainerProps) => {
  const itemStyles = useItemStyles();

  const parentTestId = useTestId();
  const dataAttributes = buildDataAttributes(data);

  const itemTestId = getTestIdFromDataAttributes(data);
  const prefixedItemTestId =
    parentTestId && itemTestId ? `${parentTestId}-${itemTestId}` : itemTestId;

  const triggerTestIdProp = prefixedItemTestId
    ? { 'data-testid': prefixedItemTestId }
    : {};

  return (
    <DropdownMenuPrimitive.Item
      css={itemStyles}
      {...props}
      {...dataAttributes}
      {...triggerTestIdProp}
    />
  );
};
