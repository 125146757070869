/** @jsx jsx */

import { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import type { FlexProps } from '@balance-web/flex';
import { Flex } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';
import type { DisallowStyleProps } from '@balance-web/utils';

import type { BadgeToneColors } from './useToneColor';
import { useBadgeToneColor } from './useToneColor';

type ValidBoxProps = Pick<FlexProps, 'data' | 'id'>;
export type NotificationBadgeProps = {
  /**
   * Optionally cap the value of a badge. When the `value` is greater than the
   * `max` prop, a "+"" will automatically be appended.
   */
  max?: number;
  /** The tone of the badge. */
  tone?: BadgeToneColors;
  /** The number displayed inside the badge. */
  value: number;
} & DisallowStyleProps &
  ValidBoxProps;

/** A visual indicator for numeric values such as tallies and scores. */
export const NotificationBadge = forwardRef<
  HTMLDivElement,
  NotificationBadgeProps
>(({ data, id, max, tone, value }, forwardedRef) => {
  const { sizing } = useTheme();
  const toneColors = useBadgeToneColor({ tone });

  const height = sizing.xsmall; // FIXME: should be resolved by the `Box` component

  return (
    <Flex
      data={data}
      id={id}
      ref={forwardedRef}
      // styles
      alignItems="center"
      borderRadius="full"
      inline
      paddingX="small"
      css={{ backgroundColor: toneColors.backgroundColor, height }}
    >
      <Text
        size="xsmall"
        overflowStrategy="nowrap"
        weight="bold"
        tabularNumbers
        css={{ color: toneColors.foregroundColor, lineHeight: 1 }}
      >
        {getBadgeLabel(value, max)}
      </Text>
    </Flex>
  );
});

// Utils
// ------------------------------

function getBadgeLabel(value: number, max?: number) {
  if (max && value >= max) {
    return `${max}+`;
  }

  return value.toString();
}
