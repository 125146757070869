/** @jsx jsx */
import React from 'react';
import type { ComponentProps } from 'react';
import { jsx } from '@balance-web/core';

import { SiteHeaderContainer } from '../SiteHeader';

import { AppLayout } from './AppLayout';

export type AppLayoutContainerProps = ComponentProps<typeof AppLayout>;

export const AppLayoutContainer = ({
  children,
  direction,
}: AppLayoutContainerProps) => {
  return (
    <AppLayout direction={direction} header={<SiteHeaderContainer />}>
      {children}
    </AppLayout>
  );
};
