import React from 'react';
import { Dropdown } from '@balance-web/dropdown-v2';

import { AppHeader } from '@reckon-web/app-header';

import { useRouter } from '../../../services/Navigation';
import { Config } from '../../../services/Config';
import { useAuth, useAuthenticatedUser } from '../../../services/Auth';

export function SiteHeaderContainer() {
  const router = useRouter();
  const { logout } = useAuth();
  const { user } = useAuthenticatedUser();

  /**
   * TODO: if your app needs to
   */
  const handleHelpClick = () => {
    router.push(Config.APPLICATION_GENERAL_HELPARTICLE_URL);
  };

  return (
    <AppHeader
      appName="Payroll"
      appVersion={Config.APPLICATION_VERSION}
      appIconUrl={Config.APPLICATION_LOGO_URL}
      /**
       * List of routes
       */
      pages={[]}
      /**
       * Current pathFragment of the url.
       * This is used to determine the selected navigation item.
       */
      selectedPage={router.asPath}
      /**
       * User menu for things like settings and sign out.
       */
      userMenu={
        (!!user && {
          userFullName: user.fullName,
          userEmail: user.email || '',
          items: (
            <React.Fragment>
              <Dropdown.Item onSelect={handleHelpClick}>
                Help & Support
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => {
                  logout({ reasonCode: 'USER_INITIATED' });
                }}
              >
                Sign out
              </Dropdown.Item>
            </React.Fragment>
          ),
        }) ||
        undefined
      }
      /**
       * Custom actions in the header displayed next to the navigation for actions like "Add thing".
       * Use components from the buttons package.
       */
      globalActions={<></>}
    />
  );
}
